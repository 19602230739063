import {
  CandidateStatus,
  VacancyStatus,
  VacancyRelevance,
  ClaimStatus,
  ReplyNewViewStatuses,
  ReplyNewStatuses,
} from "graphql/types/types";
export enum ReplyBadge {
  Systems = "Система",
  Expired = "Просрочен",
  ThirtyMinutes = "30 мин",
}

export const CANDIDATE_STATUS_TYPE: Record<CandidateStatus, string> = {
  [CandidateStatus.New]: "Новый",
  [CandidateStatus.NotThrough]: "Не дозвонились",
  [CandidateStatus.Callback]: "Нужно перезвонить",
  [CandidateStatus.DoesntGetTouch]: "Не выходит на связь",
  [CandidateStatus.Candidate]: "Кандидат",
  [CandidateStatus.Denied]: "Отказано",
  [CandidateStatus.RefusalCandidate]: "Отказ кандидата",
  [CandidateStatus.NotLookingForJob]: "Не в поиске работы",
  [CandidateStatus.WithHigherPosition]: "Кандидат выше по должности",
  [CandidateStatus.SelfSearch]: "Самостоятельный поиск",
  [CandidateStatus.TransferredMa]: "Передан МА",
};

export const VACANCY_STATUS_TYPE: Record<VacancyStatus, string> = {
  [VacancyStatus.Draft]: "Черновик",
  [VacancyStatus.Moderation]: "На модерации",
  [VacancyStatus.Published]: "Опубликована",
  [VacancyStatus.Refused]: "Заблокирована",
  [VacancyStatus.Archived]: "В архиве",
  [VacancyStatus.InWork]: "В работе",
};

export const VACANCY_RELEVANCE: Record<VacancyRelevance, string> = {
  [VacancyRelevance.Relevant]: "Актуальна",
  [VacancyRelevance.NotRelevant]: "Не актуальна",
};

export const REPLY_NEW_STATUS_TYPE: Record<
  ReplyNewStatuses & ReplyNewViewStatuses,
  string
> = {
  [ReplyNewStatuses.New]: "Новый",
  [ReplyNewStatuses.Callback]: "Нужно перезвонить",
  [ReplyNewStatuses.Processed]: "Обработан",
  [ReplyNewStatuses.InWork]: "В работе",
  [ReplyNewViewStatuses.Candidate]: "Кандидат",
  [ReplyNewViewStatuses.Denied]: "Отказано",
  [ReplyNewViewStatuses.NotLookingForJob]: "Не в поиске работы",
  [ReplyNewViewStatuses.NotThrough]: "Не дозвонились",
  [ReplyNewViewStatuses.RefusalCandidate]: "Отказ кандидата",
  [ReplyNewViewStatuses.SelfSearch]: "Самостоятельный поиск",
  [ReplyNewViewStatuses.WithHigherPosition]: "Кандидат выше по должности",
};

export const REPLY_TRANSFERABLE_STATUSES = [
  ReplyNewStatuses.InWork,
  ReplyNewStatuses.NotThrough,
  ReplyNewStatuses.Processed,
];
