import {
  CandidateStatus,
  ReplyNewStatuses,
  VacancyStatus,
} from "graphql/types/types";
import { PresetColorType, PresetStatusColorType } from "antd/es/_util/colors";
import { ReplyBadge } from "./status";

export const COLORS = {
  GREEN: "#66a84e", // $green-10
  YELLOW: "#faaa16", // $orange-10
  BLUE: "#139bd6", // $cyan-10
  RED: "#f5222d", // $red-9
  GREY: "#a6acb9", //  $neutral-7
} as const;

export type Colors = (typeof COLORS)[keyof typeof COLORS];

export const COLOR_BY_CANDIDATE_STATUS: Record<CandidateStatus, Colors> = {
  [CandidateStatus.WithHigherPosition]: COLORS.GREEN,
  [CandidateStatus.SelfSearch]: COLORS.GREEN,

  [CandidateStatus.Callback]: COLORS.YELLOW,
  [CandidateStatus.DoesntGetTouch]: COLORS.YELLOW,
  [CandidateStatus.TransferredMa]: COLORS.YELLOW,

  [CandidateStatus.New]: COLORS.BLUE,
  [CandidateStatus.Candidate]: COLORS.BLUE,

  [CandidateStatus.Denied]: COLORS.RED,
  [CandidateStatus.RefusalCandidate]: COLORS.RED,
  [CandidateStatus.NotLookingForJob]: COLORS.RED,
  [CandidateStatus.NotThrough]: COLORS.RED,
};

export const COLOR_BY_REPLY_STATUS: Record<
  ReplyNewStatuses,
  PresetColorType | PresetStatusColorType
> = {
  [ReplyNewStatuses.Callback]: "orange",
  [ReplyNewStatuses.InWork]: "green",
  [ReplyNewStatuses.New]: "blue",
  [ReplyNewStatuses.Processed]: "default",
  [ReplyNewStatuses.NotThrough]: "red",
};
export const COLOR_BY_REPLY_BADGES: Record<
  ReplyBadge,
  PresetColorType | PresetStatusColorType
> = {
  [ReplyBadge.ThirtyMinutes]: "orange",
  [ReplyBadge.Systems]: "default",
  [ReplyBadge.Expired]: "red",
};

export const COLOR_BY_VACANCY_STATUS: Record<VacancyStatus, Colors> = {
  [VacancyStatus.Published]: COLORS.GREEN,
  [VacancyStatus.Moderation]: COLORS.YELLOW,
  [VacancyStatus.Draft]: COLORS.YELLOW,
  [VacancyStatus.Archived]: COLORS.YELLOW,
  [VacancyStatus.InWork]: COLORS.BLUE,
  [VacancyStatus.Refused]: COLORS.RED,
};
