import { ReactNode } from "react";
import { ReplyNewSource, Source } from "graphql/types/types";
import {
  RabotutIcon,
  HhIcon,
  AvitoIcon,
  TelegramIcon,
  VkIcon,
  NerabIcon,
  ArketaIcon,
  ClaimIcon,
  MobileIcon,
  VpIcon,
  CpaIcon,
} from "components/UI/icons";
import { IconLabel } from "components/UI/IconLabel";

export const SOURCE_TYPE: Record<Source, string> = {
  [Source.Website]: "Rabotut.ru",
  [Source.Claim]: "Заявка",
  [Source.Reply]: "Отклик",
  [Source.Telegram]: "Telegram",
  [Source.Hh]: "HeadHunter",
  [Source.Avito]: "Avito.Работа",
  [Source.Vk]: "Вконтакте",
  [Source.Nerab]: "Нераб",
  [Source.Bcr]: "BCR",
  [Source.Mobile]: "Mobile",
  [Source.Vp]: "Ваш Персонал",
  [Source.Bluecollar]: "Bluecollar",
  [Source.Saleads]: "Saleads",
  [Source.Unicom]: "Unicom",
  [Source.Cityads]: "Cityads",
  [Source.Leadsu]: "Leadsu",
  [Source.Workle]: "Workle",
  [Source.Admitad]: "Admitad",
  [Source.Arketa]: "Arketa",
  [Source.Reactivation]: "Реактивация",
};
export const REPLY_SOURCE_TYPE: Record<ReplyNewSource, ReactNode> = {
  [ReplyNewSource.Website]: (
    <IconLabel icon={<RabotutIcon />} label="Работут" />
  ),
  [ReplyNewSource.Claim]: <IconLabel icon={<ClaimIcon />} label="CLAIM" />,
  [ReplyNewSource.Telegram]: (
    <IconLabel icon={<TelegramIcon />} label="TELEGRAM marketing" />
  ),
  [ReplyNewSource.Hh]: <IconLabel icon={<HhIcon />} label="HeadHunter" />,
  [ReplyNewSource.Avito]: <IconLabel icon={<AvitoIcon />} label="Авито" />,
  [ReplyNewSource.Vk]: <IconLabel icon={<VkIcon />} label="VK marketing" />,
  [ReplyNewSource.Nerab]: <IconLabel icon={<NerabIcon />} label="NERAB" />,
  [ReplyNewSource.Bcr]: <IconLabel icon={<CpaIcon />} label='CPA "BCR"' />,
  [ReplyNewSource.Mobile]: <IconLabel icon={<MobileIcon />} label="MOBILE" />,
  [ReplyNewSource.Vp]: <IconLabel icon={<VpIcon />} label="VP" />,
  [ReplyNewSource.Bluecollar]: (
    <IconLabel icon={<CpaIcon />} label='CPA "BLUECOLLAR"' />
  ),
  [ReplyNewSource.Saleads]: (
    <IconLabel icon={<CpaIcon />} label='CPA "SALEADS"' />
  ),
  [ReplyNewSource.Unicom]: (
    <IconLabel icon={<CpaIcon />} label='CPA "UNICON"' />
  ),
  [ReplyNewSource.Cityads]: (
    <IconLabel icon={<CpaIcon />} label='CPA "CITYADS"' />
  ),
  [ReplyNewSource.Leadsu]: (
    <IconLabel icon={<CpaIcon />} label='CPA "LEADSU"' />
  ),
  [ReplyNewSource.Workle]: (
    <IconLabel icon={<CpaIcon />} label='CPA "WORKLE"' />
  ),
  [ReplyNewSource.Admitad]: (
    <IconLabel icon={<CpaIcon />} label='CPA "ADMITAD"' />
  ),
  [ReplyNewSource.Arketa]: <IconLabel icon={<ArketaIcon />} label="ARKETA" />,
};
