import { DaDataAddressSuggestion } from "react-dadata";

import {
  JbVacancyState,
  PaymentPeriodAvito,
  PositionsRelationsType,
  UserType,
  VacancyType,
} from "graphql/types/types";
import { AvitoState } from ".";
import { transformToDaDataAddressSuggestion } from "common/helpers/transformDaData";
import { getContacts, getDescription } from "../helpers";
import { AVITO_LOGO } from "common/const/avito";
import { Type } from "components/UI/Notifications/Notification";
import {
  DEFAULT_AVITO_BONUS,
  DEFAULT_AVITO_REGISTRATION_METHOD,
} from "common/const/avito";

export const getInitialState = (
  vacancy: VacancyType,
  positionRelations?: PositionsRelationsType
): AvitoState => ({
  title: vacancy.additionalAvito?.title || vacancy.name || "",
  description: vacancy.description ?? "",
  shortDescription: vacancy.shortDescription ?? "",
  terms: vacancy.terms ?? "",
  claims: vacancy.claims ?? "",
  platformTags: positionRelations?.avitoTags ?? "",
  employment: vacancy.additionalAvito?.employment ?? "",
  experience: vacancy.additionalAvito?.experience ?? "",
  salaryFrom: vacancy.salary.from ?? 0,
  salaryTo: vacancy.salary.to ?? 0,
  managerId: vacancy?.manager?.id,
  address: transformToDaDataAddressSuggestion(vacancy.address),
  schedule: vacancy.additionalAvito?.schedule ?? "",
  profession: vacancy.additionalAvito?.profession ?? "",
  registrationMethod: getRegistrationMethod(
    vacancy.additionalAvito?.registrationMethod ?? []
  ),
  bonuses: getBonusesMethod(vacancy.additionalAvito?.bonuses ?? []),
  workFormat: vacancy.additionalAvito?.workFormat ?? [],
  paidPeriod:
    (vacancy.additionalAvito?.paidPeriod === PaymentPeriodAvito.Auto
      ? PaymentPeriodAvito.Month
      : vacancy.additionalAvito?.paidPeriod) ?? PaymentPeriodAvito.Month,
});

export const getAvitoAddress = (addressSuggestion: DaDataAddressSuggestion) => {
  const { data } = addressSuggestion || {};

  return {
    area: data?.area || "",
    region: data?.region || "",
    street: data?.street || "",
    house: data?.house || "",
    locality: data.city || data.settlement || "",
  };
};

type PayloadOptions = {
  managerList: UserType[];
  age: VacancyType["age"];
};

export const getPayload = (
  {
    description,
    shortDescription,
    terms,
    claims,
    salaryFrom,
    salaryTo,
    managerId,
    address,
    platformTags,
    paidPeriod,
    ...props
  }: AvitoState,
  { managerList, age }: PayloadOptions
) => ({
  age,
  description: getDescription({
    description,
    shortDescription,
    terms,
    claims,
    platformTags,
  }),
  location: {
    address: getAvitoAddress(address),
  },
  salary: { from: Number(salaryFrom), to: Number(salaryTo) },
  contacts: {
    name: getContacts(managerId, managerList).fullName,
    phone: getContacts(managerId, managerList).phone,
  },
  salaryDetail: {
    paidPeriod,
  },
  imageUrl: AVITO_LOGO,
  ...props,
});

const ARCHIVE = [JbVacancyState.Archived];
const PUBLICATION = [JbVacancyState.Published];
const IN_PROGRESS = [
  JbVacancyState.Archiving,
  JbVacancyState.Editing,
  JbVacancyState.Publishing,
  JbVacancyState.Created,
];
const ERROR = [JbVacancyState.Blocked, JbVacancyState.Rejected];

export const isPublicationStatus = (state: JbVacancyState) =>
  PUBLICATION.includes(state);
export const isInProgressStatus = (state: JbVacancyState) =>
  IN_PROGRESS.includes(state);
export const isErrorStatus = (state: JbVacancyState) => ERROR.includes(state);
export const isArchiveStatus = (state: JbVacancyState) =>
  ARCHIVE.includes(state);

export const getButtontext = (state?: JbVacancyState | null) => {
  if (!state) return "Опубликовать";
  if (isPublicationStatus(state)) return "Обновить";
  if (isArchiveStatus(state)) return "Подменить объявление";

  return "Опубликовать";
};

export const getNotificationTypeByState = (state: JbVacancyState): Type => {
  return (
    (isInProgressStatus(state) && "info") ||
    (isErrorStatus(state) && "error") ||
    (isPublicationStatus(state) && "success") ||
    (isArchiveStatus(state) && "warning") ||
    "error"
  );
};

export const mergeWithDefaultArray = <T>(
  source: T[],
  defaultValues: T[]
): T[] => {
  if (source.length === 0) {
    return defaultValues;
  }
  return Array.from(new Set([...source, ...defaultValues]));
};

export const getRegistrationMethod = (
  registrationMethod: string[]
): string[] => {
  return mergeWithDefaultArray(
    registrationMethod,
    DEFAULT_AVITO_REGISTRATION_METHOD
  );
};

export const getBonusesMethod = (bonuses: string[]): string[] => {
  return mergeWithDefaultArray(bonuses, DEFAULT_AVITO_BONUS);
};
