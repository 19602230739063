import React from "react";

import { Button } from "components/UI/Button";
import { Modal } from "components/UI/Modal";
import { Checkbox } from "components/UI/Checkbox";
import { PLATFORM_OPTIONS } from "common/const/options";
import { Platform } from "common/const/platform";

interface Props {
  publishItems?: Platform[];
  className?: string;
  openPublish: boolean;
  openInWork: boolean;
  loading: boolean;
  error: null | string;
  changeItems?: (val: Platform[]) => void;
  togglePublish: () => void;
  toggleInWork: () => void;
  onPublishClick: () => void;
  onClosePublishClick: () => void;
}

export const VacancyModals: React.FC<Props> = ({
  publishItems,
  loading,
  error,
  openPublish,
  openInWork,
  toggleInWork,
  togglePublish,
  onPublishClick,
  onClosePublishClick,
  changeItems,
}) => {
  return (
    <>
      <Modal
        open={openPublish}
        title="Опубликовать вакансию"
        onCancel={togglePublish}
        footer={
          <Button disabled={!publishItems?.length} onClick={onPublishClick}>
            Продолжить
          </Button>
        }
      >
        <Checkbox
          className="vacancyInfo-container-checkbox"
          type="multi"
          options={PLATFORM_OPTIONS}
          value={publishItems}
          onChange={(v) => changeItems?.(v as unknown as Platform[])}
        />
      </Modal>

      <Modal
        open={openInWork}
        title="Вакансия будет перенесена в раздел Холодный поиск"
        onCancel={toggleInWork}
        noSidePanel={true}
        footer={
          <div className="vacancyInfo-container-close">
            <Button
              loading={loading}
              onClick={onClosePublishClick}
              className="vacancyInfo-container-close-publish"
            >
              Подтвердить
            </Button>
            <Button
              variant="primary"
              onClick={toggleInWork}
              className="vacancyInfo-container-close-publish"
            >
              Отменить
            </Button>
            {error && (
              <div className="vacancyInfo-container-error">{error}</div>
            )}
          </div>
        }
      />
    </>
  );
};
