import React, { useMemo, useState } from "react";
import { Layout, Menu } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import { useSelector } from "react-redux";

import {
  UnorderedListOutlined,
  CheckOutlined,
  CheckCircleOutlined,
  BookOutlined,
  Chevron,
  CheckVacancy,
  NewReplies,
  WorkWitchCandidate,
  ColdSearch,
  Reactivation,
  CreateCandidate,
} from "components/UI/icons";
import { authInfo, role } from "services/store/modules/auth";
import { UserRole } from "graphql/types/types";
import { Button } from "components/UI/Button";
import { ROUTE } from "routes";
import { getActiveMenuKey } from "common/helpers/location";
import { Logo } from "components/Logo";
import { useChangeTitle } from "common/hooks/header/useChangeTitle";
import { isProduction } from "common/utils/environments";
import { hasEmployeeMaAccess, isAdmin } from "common/helpers/role";

import "./styles.scss";

const { Sider } = Layout;

interface SidebarProps {
  isCollapsed: boolean;
  onCollapse(): void;
}

type MenuItemsParams = {
  company: number;
  userRole?: UserRole;
  userDepartmentName?: string;
};

const getMenuItems = ({
  company,
  userRole,
  userDepartmentName,
}: MenuItemsParams) => [
  {
    label: "Вакансии",
    path: "/vacancies",
    icon: <UnorderedListOutlined />,
    isVisible: true,
    isBeta: false,
  },
  {
    label: "Отклики",
    path: "/replies",
    icon: <CheckOutlined />,
    isVisible: false,
    isBeta: false,
  },
  {
    label: "Заявки",
    path: "/claims",
    icon: <CheckCircleOutlined />,
    isVisible: company === 1 && userRole !== UserRole.Moderator,
    isBeta: false,
  },
  {
    label: "База знаний",
    path: "/knowledge-base",
    icon: <BookOutlined />,
    isVisible: userDepartmentName === "КЦ",
    isBeta: false,
  },
  {
    label: "Проверка вакансий",
    path: ROUTE.VACANCIES,
    icon: <CheckVacancy />,
    isVisible: true,
    isBeta: true,
  },
  {
    label: "Отклики",
    path: ROUTE.REPLIES,
    icon: <NewReplies />,
    isVisible: true,
    isBeta: true,
  },
  {
    label: "Реактивация",
    path: ROUTE.REACTIVATION_VACANCIES,
    icon: <Reactivation />,
    isVisible: userRole ? isAdmin(userRole) : false,
    isBeta: true,
  },
  {
    label: "Холодный поиск",
    path: ROUTE.COLD_SEARCH,
    icon: <ColdSearch />,
    isVisible: !isProduction(),
    isBeta: true,
  },
  {
    label: "Работа с кандидатом",
    path: ROUTE.ADAPTATION_LIST,
    icon: <WorkWitchCandidate />,
    isVisible: userRole ? hasEmployeeMaAccess(userRole) : false,
    isBeta: true,
  },
  {
    label: "Общая база кандидатов",
    path: ROUTE.CANDIDATES,
    icon: <BookOutlined />,
    isVisible: true,
    isBeta: true,
  },
  {
    label: "Создание кандидата",
    path: ROUTE.CREATE_CANDIDATE,
    icon: <CreateCandidate />,
    isVisible: true,
    isBeta: true,
  },
];

export const Sidebar: React.FC<SidebarProps> = ({
  isCollapsed,
  onCollapse,
}) => {
  const { pathname, search } = useLocation();
  const history = useHistory();

  const userRole = useSelector(role);
  const userDepartmentName = useSelector(authInfo)?.department?.name;
  const company = useSelector(authInfo)?.company?.id || 0;

  const [selectedKey, setSelectedKey] = useState(
    getActiveMenuKey(pathname, search)
  );

  const menuItems = useMemo(
    () => getMenuItems({ company, userRole, userDepartmentName }),
    [company, userRole, userDepartmentName]
  );

  const title = useMemo(
    () => menuItems.find((item) => item.path === pathname)?.label || null,
    [menuItems, pathname]
  );

  useChangeTitle({ title });

  const handleClick: MenuClickEventHandler = ({ key }) => {
    setSelectedKey(getActiveMenuKey(key, search));
    history.push({ pathname: key });
  };

  return (
    <Sider
      className="sidebar"
      trigger={null}
      width={230}
      theme={"light"}
      collapsible
      collapsed={isCollapsed}
      onCollapse={onCollapse}
    >
      <Logo isCollapsed={isCollapsed} />

      <Menu selectedKeys={[selectedKey]} mode={"inline"} onClick={handleClick}>
        {menuItems.map(({ path, label, icon, isVisible }, i) => {
          if (!isVisible) {
            return null;
          }

          const isLine = menuItems[i].isBeta && !menuItems[i - 1]?.isBeta;

          return (
            <>
              {isLine && <hr className="sidebar-hr" />}
              <Menu.Item
                key={path}
                icon={<span className="icon">{icon}</span>}
                title={label}
              >
                {label}
              </Menu.Item>
            </>
          );
        })}
      </Menu>

      <Button
        icon={
          <Chevron
            style={{
              transform: isCollapsed ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        }
        variant="primary"
        size="small"
        onClick={onCollapse}
      />
    </Sider>
  );
};
