export const MESSAGE_CONFIRM =
  "У вас есть несохраненные изменения. Вы уверены, что хотите уйти?";

export const VALIDATION_MESSAGE = {
  MAX_LIMIT:
    "Превышен лимит символов для этого поля с учетом HTML-разметки. Сократите текст.",
  FULL_ADDRESS:
    "Необходимо указать полный адрес (населенный пункт, улица, номер дома",
  NAME: "Необходимо указать название",
  SALARY: "Необходимо указать уровень дохода",
};

export const STATUS_MESSAGE = {
  SUCCESS: "Статус успешно изменен",
  ERROR: "Ошибка изменения статуса",
};

export const CANDIDATE_MESSAGE = {
  SUCCESS: "Данные успешно сохранены",
  ERROR: "Ошибка сохранения кандидата",
  CREATE_ERROR: "Ошибка создания кандидата",
};

export const VACANCY_MESSAGE = {
  SUCCESS: "Вакансия успешно сохранена",
  ACCEPT: "Вакансия принята в работу",
  DELETE: "Вакансия удалена",
  IN_WORK: "Вакансия в работе",
  ERROR: "Ошибка вакансии",
  DELETE_ERROR: "Ошибка удаления вакансии",
  SAVE_ERROR: "Ошибка сохранения вакансии",
  HIDE_ERROR: "Ошибка скрытия вакансии",
};

export const REPLY_MESSAGE = {
  IN_WORK: "Отклик в работе",
  ERROR: "Ошибка загрузки отклика",
};

export const ERROR_MESSAGE = {
  SYSTEM: "Ошибка системы",
  CALL: "Ошибка звонка",
  DICTIONARY: "Ошибка загрузки словарей",
  DATA: "Ошибка сохранения данных",
};

export const SUCCESS_MESSAGE = {
  DATA: "Данные успешно сохранены",
};
