import axios from "axios";
import { DaDataAddressMetro } from "react-dadata/dist/variants/address/address-types";

interface MetroData {
  suggestions: { data: { metro: DaDataAddressMetro[] } }[];
}

export async function getMetroDadata(
  query: string
): Promise<DaDataAddressMetro[]> {
  const apiUrl: string | undefined = process.env.REACT_APP_FIAS_URL ?? "";
  const token: string | undefined = process.env.REACT_APP_FIAS_TOKEN;

  try {
    const res = await axios.post<MetroData>(
      apiUrl,
      {
        query: query,
        count: 1,
      },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );

    return res.data.suggestions[0]?.data.metro || [];
  } catch (err) {
    return [];
  }
}
