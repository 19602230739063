import humps from "humps";

const convertCallback = <T>(key: string, convert: Function): T => {
  const lastSymbol = key.slice(-1);
  const newKey = convert(key);
  // @ts-ignore
  return lastSymbol === "-" ? `${newKey}-` : newKey;
};

export const camelize = <T>(obj: T) => humps.camelizeKeys(obj, convertCallback);
