import React from "react";
import { IconType } from "common/commonTypes/types";

export const CpaIcon: React.FC<IconType> = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3334 4L9.00008 10.3333L5.66675 7L0.666748 12"
        stroke="#0057AC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3333 4H15.3333V8"
        stroke="#0057AC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
