import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useAppDispatch, useAppSelector } from "services/store/store";
import { Props as TableProps, Table } from "components/UI/Table";
import {
  PageInfoType,
  ReplyNewClientType,
  ReplyNewStatuses,
  UserClientType,
} from "graphql/types/types";
import { Pagination, PaginationPayload } from "components/UI/Pagination";
import { ROUTE } from "routes";
import {
  REPLY_NEW_STATUS_TYPE,
  REPLY_TRANSFERABLE_STATUSES,
} from "common/const/status";
import {
  COLOR_BY_REPLY_BADGES,
  COLOR_BY_REPLY_STATUS,
} from "common/const/colors";
import { Spinner } from "components/UI/Spinner";
import { REPLY_SOURCE_TYPE } from "common/const/source";
import { pluralize } from "common/utils";
import { getFullName } from "common/helpers/string";
import { department } from "services/store/modules/directory";
import { Checkbox } from "components/UI/Checkbox";
import { Button } from "components/UI/Button";
import { ReplyTransferListModal } from "../ReplyTransferListModal";
import { hasManagerAccess, hasModerationAccess } from "common/helpers/role";
import { transferReplyList } from "services/store/modules/replies/actions";
import toaster from "components/UI/Notifications/Notification";
import { Tag } from "components/UI/Tag";
import { CopyButton } from "components/UI/CopyButton";
import { IUser } from "services/store/modules/user/types";
import { getDateFormat } from "common/utils/date";
import { ReplyFilters } from "../ReplyFilters";
import { userList } from "services/store/modules/user/selectors";
import { ReplyFiltersInitialState } from "services/store/modules/replies/types";
import { PAGINATION } from "common/const/pagination";
import { getBadgeLabel, getDisplayProject } from "./helpers";
import { ERROR_MESSAGE } from "common/const/messages";

import "./styles.scss";

type Props = {
  className?: string;
  replies: ReplyNewClientType[];
  meta: PageInfoType;
  loading: boolean;
  userData: IUser;
  filters: ReplyFiltersInitialState;
  setReplyFilters: (filters: ReplyFiltersInitialState) => void;
  onChangePagination: (
    pagination: PaginationPayload,
    filters: ReplyFiltersInitialState
  ) => void;
};

export const RepliesTable: React.FC<Props> = ({
  replies,
  meta,
  loading,
  onChangePagination,
  userData,
  filters,
  setReplyFilters,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const departmentList = useAppSelector(department);
  const users = useAppSelector(userList);

  const [selectedReplyIds, setSelectedReplyIds] = useState<string[]>([]);
  const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);

  const { handleSubmit, control, reset, formState } =
    useForm<ReplyFiltersInitialState>({
      defaultValues: filters,
    });

  const handleFilter = (filtersData: ReplyFiltersInitialState) => {
    setReplyFilters(filtersData);
    onChangePagination(PAGINATION.REPLIES, filtersData);
  };

  const handleSelectRow = (id: string, checked: boolean) => {
    setSelectedReplyIds((prev) =>
      checked ? [...prev, id] : prev.filter((key) => key !== id)
    );
  };

  const handleTransferReplies = async (newUserId: UserClientType["id"]) => {
    try {
      await dispatch(
        transferReplyList({
          ids: selectedReplyIds,
          newUserId,
        })
      ).unwrap();
      toaster.success({ title: "Отклики успешно переданы" });
      setIsTransferModalVisible(false);
      setSelectedReplyIds([]);
    } catch (error) {
      toaster.error({ title: ERROR_MESSAGE.SYSTEM });
    }
  };

  const userHasAccess = hasModerationAccess(userData.role);

  const getSelectableReplies = () =>
    replies.filter((r) => !REPLY_TRANSFERABLE_STATUSES.includes(r.status));

  const isAllSelectableSelected = () => {
    const selectable = getSelectableReplies();
    return (
      selectable.length > 0 && selectedReplyIds.length === selectable.length
    );
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedReplyIds(getSelectableReplies().map((r) => r.id));
    } else {
      setSelectedReplyIds([]);
    }
  };

  const selectColumn = {
    title: (
      <Checkbox
        type="single"
        checked={isAllSelectableSelected()}
        onSingleChange={(e) => handleSelectAll(e.target.checked)}
      />
    ),
    dataIndex: "select",
    key: "select",
    width: 50,
    onCell: () => ({
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation();
      },
    }),
    render: (_, { id, status }) => (
      <>
        <Checkbox
          type="single"
          checked={selectedReplyIds.includes(id)}
          disabled={REPLY_TRANSFERABLE_STATUSES.includes(status)}
          onSingleChange={(e) => {
            handleSelectRow(id, e.target.checked);
          }}
        />
      </>
    ),
  };

  const columns: TableProps<ReplyNewClientType>["columns"] = [
    ...(userHasAccess ? [selectColumn] : []),
    {
      title: "Дата",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => {
        return (
          <div
            className="nowrap"
            title={getDateFormat(createdAt, "DD.MM.YY HH:mm")}
          >
            {getDateFormat(createdAt, "DD.MM.YY HH:mm")}
          </div>
        );
      },
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (
        status: ReplyNewStatuses,
        { isThirtyMinutes, isExpired, lastModifiedBy }
      ) => {
        const badgeLabel = getBadgeLabel(
          status,
          isExpired,
          isThirtyMinutes,
          lastModifiedBy
        );

        return (
          <div className="replies-table-column-tags">
            <Tag color={COLOR_BY_REPLY_STATUS[status]}>
              {REPLY_NEW_STATUS_TYPE[status]}
            </Tag>
            {badgeLabel && (
              <Tag color={COLOR_BY_REPLY_BADGES[badgeLabel]}>{badgeLabel} </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "ФИО кандидата",
      dataIndex: "lastName",
      key: "lastName",
      render: (_, reply) => <div className="nowrap">{getFullName(reply)}</div>,
    },
    {
      title: "ID вакансии",
      dataIndex: "id",
      key: "id",
      render: (_, { vacancy }) => <CopyButton text={String(vacancy.id)} />,
    },
    {
      title: "Вакансия",
      dataIndex: "vacancy",
      key: "vacancy",
      width: 180,
      render: (vacancy) => (
        <div className="nowrap" title={vacancy.name}>
          {vacancy.name}
        </div>
      ),
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      render: (_, { vacancy }) => (
        <div
          className="replies-table-column-address"
          title={vacancy.address.value ?? ""}
        >
          {vacancy.address.value ?? ""}
        </div>
      ),
    },
    {
      title: "Торговая точка",
      dataIndex: "vacancy",
      key: "vacancy",
      render: (vacancy) => {
        return (
          <div className="replies-table-column-info">
            {getDisplayProject(vacancy)}
          </div>
        );
      },
    },
    {
      title: "Источник",
      dataIndex: "source",
      key: "source",
      render: (source) => REPLY_SOURCE_TYPE[source],
    },

    {
      title: "Менеджер",
      dataIndex: "manager",
      key: "manager",
      width: 180,
      render: (manager) => <div className="nowrap">{getFullName(manager)}</div>,
    },
    {
      title: "Департамент",
      dataIndex: "vacancy",
      key: "vacancy",
      render: (vacancy) => (
        <div className="nowrap">
          {departmentList.find((dep) => dep.id === Number(vacancy.departmentId))
            ?.name ?? ""}
        </div>
      ),
    },
    //TODO: Резюме скрыть со страницы (но не убирать из кода) задача: https://git.infra.rabotut.ru/rabotut/issues-rteam/-/issues/176
    // {
    //   title: "Резюме",
    //   dataIndex: "candidate",
    //   key: "candidate",
    //   width: 75,
    //   render: (candidate: ReplyType["candidate"]) => {
    //     if (!candidate?.resumeUrl) {
    //       return null;
    //     }
    //
    //     return getIsLink(candidate.resumeUrl) ? (
    //       <Dropdown
    //         trigger={["click"]}
    //         items={getLinkItems(candidate.resumeUrl)}
    //       >
    //         <span
    //           className="replies-table-link-dropdown-trigger"
    //           onClick={(e) => e.stopPropagation()}
    //         >
    //           <Ellipsis />
    //         </span>
    //       </Dropdown>
    //     ) : null;
    //   },
    // },
  ];

  return (
    <div className="replies-table">
      <div className="replies-table-head">
        {userHasAccess && (
          <Button
            className="replies-table-head-transfer-btn"
            onClick={() => setIsTransferModalVisible(true)}
            disabled={!selectedReplyIds.length}
          >
            {selectedReplyIds.length > 0
              ? `Передать [${selectedReplyIds.length}] ${pluralize(
                  selectedReplyIds.length,
                  "отклик",
                  "отклика",
                  "откликов"
                )}`
              : "Передать отклики"}
          </Button>
        )}
        {hasManagerAccess(userData.role) && (
          <ReplyFilters
            loading={loading}
            department={departmentList}
            control={control}
            handleSubmit={handleSubmit}
            reset={reset}
            formState={formState}
            onFilter={handleFilter}
            users={users}
            userRole={userData.role}
            setReplyFilters={setReplyFilters}
          />
        )}
      </div>
      {loading ? (
        <Spinner className="replies-table-spinner" loading={loading} />
      ) : (
        <>
          <Table
            draggableScroll
            tableLayout="auto"
            dataSource={replies}
            columns={columns}
            onRow={(reply) => ({
              onClick: () => {
                history.push(`${ROUTE.REPLY}/${reply.id}`);
              },
            })}
          />
          <Pagination
            className="candidates-container-pagination"
            total={meta.total}
            limit={meta.limit}
            offset={meta.offset}
            onChange={(pagination) => onChangePagination(pagination, filters)}
          />
        </>
      )}
      <ReplyTransferListModal
        onClose={() => setIsTransferModalVisible(false)}
        visible={isTransferModalVisible}
        onSubmit={handleTransferReplies}
      />
    </div>
  );
};
