import {
  ReplyNewClientType,
  ReplyNewStatuses,
  VacancyType,
} from "graphql/types/types";
import { ReplyBadge } from "common/const/status";

export const getBadgeLabel = (
  status: ReplyNewStatuses,
  isExpired: ReplyNewClientType["isExpired"],
  isThirtyMinutes: ReplyNewClientType["isThirtyMinutes"],
  lastModifiedBy: ReplyNewClientType["lastModifiedBy"]
): string | null => {
  switch (true) {
    case status === ReplyNewStatuses.New && isExpired:
      return ReplyBadge.Expired;

    case status === ReplyNewStatuses.New && isThirtyMinutes:
      return ReplyBadge.ThirtyMinutes;

    case status === ReplyNewStatuses.Processed && lastModifiedBy === 0:
      return ReplyBadge.Systems;

    default:
      return null;
  }
};

export const getDisplayProject = (vacancy: VacancyType): string => {
  const project = vacancy?.callCenterInfo?.project ?? "";
  return project.split(",")[0];
};
