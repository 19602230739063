import { createRoot } from "react-dom/client";
import { ConfigProvider } from "antd";
import ruRU from "antd/lib/locale/ru_RU";
import "./index.css";
import App from "./App";
import "antd/dist/antd.min.css";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <ConfigProvider locale={ruRU}>
    <App />
  </ConfigProvider>
);
