import React, { useState } from "react";

import {
  UserRole,
  UserType,
  VacancyStatus,
  VacancyType,
} from "graphql/types/types";
import { RabotutForm } from "components/redezign/Vacancy/components/RabotutForm";
import { Modal } from "components/UI/Modal";
import { Tabs } from "components/UI/Tabs";
import { useToggle } from "common/hooks/useToggle";
import { Title } from "components/UI/Title";
import { Button } from "components/UI/Button";
import { useAppDispatch, useAppSelector } from "services/store/store";
import {
  changeVacancyStatus,
  deleteVacancy,
} from "services/store/modules/vacancy/actions";
import { RejectVacancyModal } from "../RejectVacancyModal";
import { VacancyActivity } from "../VacancyActivity";
import {
  deleteVacancy as deleteVacancyList,
  setVacancyStatus,
} from "services/store/modules/vacanciesList";
import toaster from "components/UI/Notifications/Notification";
import { selectUsersByRoles } from "services/store/modules/user/selectors";
import { DisabledVacancies } from "services/store/modules/vacanciesList/types";
import { VACANCY_MESSAGE } from "common/const/messages";

import "./styles.scss";

type Props = {
  vacancy: VacancyType;
  disabledList: DisabledVacancies;
  open: boolean;
  user: UserType;
  onCancel: () => void;
};

export const VacancyModals: React.FC<Props> = ({
  vacancy,
  user,
  open,
  disabledList,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const [disabled, toggleDisabled, setValue] = useToggle(true);
  const [openAcceptModal, toggleAcceptModal] = useToggle(false);
  const [openDeleteModal, toggleDeleteModal] = useToggle(false);
  const [openRejectModal, toggleRejectModal] = useToggle(false);

  const managerList = useAppSelector((state) =>
    selectUsersByRoles(state, [UserRole.Manager, UserRole.Hrbp])
  );

  const [activeKey, setActiveKey] = useState("vacancy");
  const [loading, setLoading] = useState(false);

  const items = [
    {
      key: "vacancy",
      label: "Вакансия",
      children: (
        <RabotutForm
          vacancy={vacancy}
          user={user}
          managerList={managerList}
          disabledList={disabledList}
          mode="editing"
          disabled={disabled}
          toggleDisabled={toggleDisabled}
          openRejectModal={toggleRejectModal}
          openAcceptModal={toggleAcceptModal}
          openDeleteModal={toggleDeleteModal}
          onCancel={onCancel}
        />
      ),
    },
    {
      key: "activity",
      label: "Активность",
      children: <VacancyActivity vacancyId={vacancy.id} />,
    },
  ];

  const handleTabClick = (key: string) => {
    setActiveKey(key);
  };

  const acceptVacancy = async () => {
    setLoading(true);
    const payload = { id: vacancy.id, status: VacancyStatus.InWork };

    await dispatch(changeVacancyStatus(payload))
      .then(() => {
        toaster.success({ title: VACANCY_MESSAGE.ACCEPT });
        dispatch(setVacancyStatus(payload));
        toggleAcceptModal();
        onCancel();
      })
      .catch(() => {
        toaster.error({ title: VACANCY_MESSAGE.ERROR });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeVacancy = async () => {
    setLoading(true);

    await dispatch(deleteVacancy({ id: vacancy.id }))
      .then(() => {
        toaster.success({ title: VACANCY_MESSAGE.DELETE });
        dispatch(deleteVacancyList({ id: vacancy.id }));
      })
      .catch(() => {
        toaster.error({ title: VACANCY_MESSAGE.DELETE_ERROR });
      })
      .finally(() => {
        setLoading(false);
        toggleDeleteModal();
      });

    onCancel();
  };

  return (
    <>
      <Modal
        open={open}
        size="large"
        onCancel={() => {
          setValue(true);
          onCancel();
        }}
        footer={null}
      >
        <Tabs
          defaultActiveKey="vacancy"
          activeKey={activeKey}
          onTabClick={handleTabClick}
          items={items}
        />
      </Modal>

      <Modal
        open={openAcceptModal}
        size="small"
        onCancel={toggleAcceptModal}
        footer={null}
      >
        <Title type="h3">Вы уверены, что хотите принять вакансию?</Title>
        <div className="vacancyModals-buttons">
          <Button loading={loading} onClick={acceptVacancy}>
            Да
          </Button>
          <Button variant="primary" onClick={toggleAcceptModal}>
            Нет
          </Button>
        </div>
      </Modal>

      <Modal
        open={openDeleteModal}
        size="small"
        onCancel={toggleDeleteModal}
        footer={null}
      >
        <Title type="h3">Вы уверены, что хотите удалить вакансию?</Title>
        <div className="vacancyModals-buttons">
          <Button loading={loading} onClick={removeVacancy}>
            Да
          </Button>
          <Button variant="primary" onClick={toggleDeleteModal}>
            Нет
          </Button>
        </div>
      </Modal>

      <RejectVacancyModal
        vacancyId={vacancy.id}
        open={openRejectModal}
        onCancel={() => {
          toggleRejectModal();
          onCancel();
        }}
      />
    </>
  );
};
