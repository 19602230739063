import React from "react";
import { Form } from "antd";
import cn from "classnames/dedupe";
import { CheckboxGroupProps } from "antd/es/checkbox";

import { Checkbox } from "../../Checkbox";
import { FormError } from "../FormError";
import { FormLabel } from "../FormLabel";

import "./styles.scss";

export interface Props extends CheckboxGroupProps {
  label: string;
  type?: "single" | "multi";
  required?: boolean;
  error?: string;
}
export const FormCheckbox: React.FC<Props> = ({
  className,
  label,
  type = "multi",
  error,
  required,
  ...props
}) => (
  <Form.Item
    label={<FormLabel label={label} required={required} />}
    className={cn("ui-form-checkbox", className)}
  >
    <Checkbox type={type} {...props} />

    {error && <FormError error={error} />}
  </Form.Item>
);
