import { hasEmployeeCcAccess } from "common/helpers/role";
import { UserType, VacancyStatus } from "graphql/types/types";
import { isDepartmentValid } from "./helpers";

type GetActionButtonsPayload = {
  withGetInWork?: boolean;
  withPublish?: boolean;
  withStayInWork?: boolean;
  isVacancyRowDisabled: boolean;
  status: VacancyStatus;
  userData: UserType;
  peopleCountOpen: boolean;
  inWork: boolean;
  fromReactivationVacancies?: boolean;
  handleVacancyVisible: () => Promise<void>;
  togglePublish: () => void;
  toggleInWork: () => void;
  manageVacancy: () => void;
};

export const getActionButtons = ({
  withGetInWork,
  withPublish,
  withStayInWork,
  isVacancyRowDisabled,
  status,
  userData,
  inWork,
  peopleCountOpen,
  fromReactivationVacancies,
  handleVacancyVisible,
  togglePublish,
  toggleInWork,
  manageVacancy,
}: GetActionButtonsPayload) => [
  {
    text: "Взять в работу",
    className: "vacancyInfo-container-publish",
    visible: withGetInWork,
    disabled:
      status === VacancyStatus.Moderation ||
      isVacancyRowDisabled ||
      peopleCountOpen ||
      inWork ||
      !hasEmployeeCcAccess(userData.role),
    onClick: fromReactivationVacancies ? manageVacancy : handleVacancyVisible,
  },
  {
    text: "Опубликовать на JobBoard",
    className: "vacancyInfo-container-publish",
    visible: withPublish,
    disabled:
      status === VacancyStatus.Moderation ||
      !hasEmployeeCcAccess(userData.role) ||
      !isDepartmentValid(userData.departmentId),
    onClick: togglePublish,
  },
  {
    text: "Оставить в работе",
    className: "vacancyInfo-container-publish",
    visible: false,
    variant: "primary" as const,
    onClick: toggleInWork,
  },
];
