import React from "react";
import { useAppDispatch } from "services/store/store";

import { FieldInput } from "components/UI/Table/FieldInput";
import { updateCallPlan } from "services/store/modules/plans/actions";
import { updatePlanCount } from "services/store/modules/plans";
import toaster from "components/UI/Notifications/Notification";
import { ERROR_MESSAGE } from "common/const/messages";

type Props = {
  initialValue: number;
  managerId: number;
};

export const FieldPlanCount: React.FC<Props> = ({
  initialValue,
  managerId,
}) => {
  const dispatch = useAppDispatch();

  const handleSave = async (value: string | number) => {
    const numberValue = Number(value);

    if (numberValue !== 0 && numberValue <= initialValue) {
      toaster.error({
        title: "Значение должно быть равно 0 или больше текущего значения",
      });
      return Promise.reject();
    }

    await dispatch(
      updateCallPlan({
        countCalls: numberValue,
        managerId,
      })
    )
      .unwrap()
      .then(({ updateHotCallPlan }) => {
        dispatch(
          updatePlanCount({
            userId: updateHotCallPlan?.userId ?? 0,
            countCalls: updateHotCallPlan?.countCalls ?? 0,
          })
        );
        toaster.success({ title: "План изменен" });
      })
      .catch((err) => toaster.error({ title: ERROR_MESSAGE.DATA }));
  };

  return (
    <FieldInput type="number" fieldValue={initialValue} onSave={handleSave} />
  );
};
