import React from "react";
import { Typography } from "antd";
import classNames from "classnames";

interface TextProps {
  className?: string;
  text?: React.ReactNode;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const Text: React.FC<TextProps> = ({ className, text, children, ...props }) => {
  const textClassNames = classNames("ui-text", className);
  return (
    <Typography className={textClassNames} {...props}>
      {text ?? children}
    </Typography>
  );
};

export default Text;
