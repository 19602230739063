import { gql } from "@apollo/client";

export const CANDIDATE_FOR_ADAPTATION = gql`
  query candidateForAdaptation($candidateId: UUID!) {
    candidateForAdaptation(candidateId: $candidateId) {
      ... on CandidateWithVacancyType {
        actualAddress {
          block
          blockType
          city
          cityType
          house
          houseType
          lat
          lng
          region
          regionType
          settlement
          street
          streetType
          value
        }
        age
        status
        citizenshipId
        comments {
          comment
          createdAt
          manager {
            city
            companyId
            departmentId
            email
            firstName
            id
            phone
            role
            secondName
          }
        }
        contacts {
          contact
          id
          isMain
        }
        firstName
        secondName
        patronymic
        positionsNew {
          createdAt
          id
          position {
            categoryId
            id
            key
            name
            restriction
          }
        }
        source
        vacancy {
          id
          inWork
          inBlocked
          managersIds
          name
          additionalAvito {
            bonuses
            employment
            experience
            profession
            registrationMethod
            schedule
            title
            workFormat
            managerId
            whoPublished
          }
          additionalHh {
            acceptHandicapped
            acceptKids
            acceptTemporary
            area
            driverLicense
            experience
            languages
            name
            professionalRoles
          }
          address {
            block
            blockType
            city
            cityType
            house
            houseType
            lat
            lng
            region
            regionType
            settlement
            street
            streetType
            value
          }
          age {
            from
            to
          }
          callCenterInfo {
            additionalMotivation
            addresses
            compensationMedicalBook
            description
            driverLicense
            experience
            farePayment
            goodsCategory
            mobilePayment
            payoutsRfp
            personalCar
            presenceOfPda
            project
            registrationOfForeignCitizens
            typesOfJobRegistration
            vaccination
          }
          citizenship {
            id
            key
            name
          }
          nationalities {
            nationality {
              id
              key
              name
            }
          }
          contacts {
            email
            fullName
            phone
          }
          employer {
            id
            key
            logo
            name
          }
          employment {
            id
            key
            name
          }
          extraAvito {
            publicationFinishTime
            remoteId
            remoteUrl
            message
            state
          }
          extraHh {
            publicationFinishTime
            remoteId
            remoteUrl
            message
            state
          }
          location {
            lat
            lng
          }
          manager {
            city
            companyId
            departmentId
            email
            firstName
            phone
            id
            role
            secondName
          }
          salary {
            currency
            from
            gross
            to
          }
          refusal {
            id
            key
            name
            type
          }
          tag {
            color
            colorBg
            key
            name
          }
          candidateCount
          city
          claims
          closedAt
          counterparty
          createdAt
          deletedAt
          description
          kladrId
          medicalBook
          rate
          metro {
            distance
            line
            name
          }
          peopleCount
          peopleCountOpen
          peopleCountClose
          phonePresence
          position {
            categoryId
            id
            key
            name
            restriction
          }
          positionId
          priority
          platformTags
          publishedAt
          publishedOnAvito
          publishedOnHh
          publishedOnRabotut
          reason
          refusalText
          region
          relevance
          replyCount
          replyCountNew
          responsiblePerson
          route
          schedule
          shiftEnd
          shiftStart
          shortDescription
          showContacts
          status
          subdivision
          terms
          tradePoint
          updatedAt
        }
      }
      ... on Error {
        errors
        statusCode
        message
      }
    }
  }
`;
