import React from "react";
import {
  AddressSuggestions,
  DaDataAddress,
  DaDataAddressBounds,
} from "react-dadata";
import classNames from "classnames";

import { BaseProps } from "react-dadata/dist/base-suggestions";
import { SizeType } from "../types";

import "react-dadata/dist/react-dadata.css";
import "./styles.scss";

type Dictionary = {
  [key: string]: unknown;
};

export type Props = Omit<BaseProps<DaDataAddress>, "token"> & {
  containerClassName?: string;
  disabled?: boolean;
  error?: string;
  size?: SizeType;
  filterLanguage?: "ru" | "en";
  filterFromBound?: DaDataAddressBounds;
  filterToBound?: DaDataAddressBounds;
  filterLocations?: Dictionary[];
  filterLocationsBoost?: Dictionary[];
  filterRestrictValue?: boolean;
};

const TOKEN = process.env.REACT_APP_FIAS_TOKEN ?? "";

export const SelectDadata: React.FC<Props> = ({
  containerClassName,
  delay = 500,
  disabled = false,
  error,
  size = "large",
  suggestionsClassName,

  ...props
}) => {
  const containerClassNames = classNames(
    "ui-select-dadata",
    containerClassName,
    {
      "ui-select-dadata--disabled": disabled,
      "ui-select-dadata--error": error,
      [`ui-select-dadata--${size}`]: size,
    }
  );

  return (
    <AddressSuggestions
      inputProps={{ disabled }}
      delay={delay}
      containerClassName={`react-dadata react-dadata__container ${containerClassNames}`}
      token={TOKEN}
      suggestionsClassName={`react-dadata__suggestions ${suggestionsClassName}`}
      {...props}
    />
  );
};
