import React, { useState } from "react";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { ToastContainer } from "react-toastify";

import { Breadcrumbs } from "components/UI/Breadcrumbs";
import { Sidebar } from "components/redezign/Sidebar";
import { authInfo, isMenuOpen, toSetMenu } from "services/store/modules/auth";
import { isModalResult } from "services/store/modules/calls";
import { useAppDispatch } from "services/store/store";
import { useAppSelector } from "services/store/store";
import { SidePanel } from "components/SidePanel";
import { useSockets } from "common/hooks/sockets/useSockets";
import { Header } from "components/Header";
import { Call } from "components/Calls/Call";
import { useInitialActions } from "common/hooks/useInitialActions";

import "./layout.css";

type Props = {
  children?: React.ReactNode;
};

export const MainLayout: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();

  useSockets();
  const { fetchInitialActions } = useInitialActions();

  const authData = useAppSelector(authInfo);
  const isMenuOpened = useAppSelector(isMenuOpen);
  const isModalCallResult = useAppSelector(isModalResult);

  const [isSideBarVisible, setSideBarVisible] = useState(false);

  React.useEffect(() => {
    fetchInitialActions();
  }, []);

  return (
    <Layout className="layout layout--main">
      <Sidebar
        isCollapsed={!isMenuOpened}
        onCollapse={() => dispatch(toSetMenu(!isMenuOpened))}
      />
      <Layout
        className={`layout__wrapper ${
          !isMenuOpened ? "is-collapsed" : "is-expanded"
        }`}
      >
        <Header
          authData={authData}
          setVisible={() => setSideBarVisible(true)}
        />

        <Content className="layout__content">
          <Breadcrumbs />
          {props.children}
        </Content>

        {isModalCallResult ? <Call isModalOpen={isModalCallResult} /> : null}

        <ToastContainer />
      </Layout>

      {authData && (
        <SidePanel
          isVisible={isSideBarVisible}
          user={authData}
          onClose={() => setSideBarVisible(false)}
        />
      )}
    </Layout>
  );
};

export default MainLayout;
