import React from "react";
import cn from "classnames";
import { Copy } from "components/UI/icons";
import { useCopyToClipboard } from "common/hooks/useCopyToClipboard";

import "./styles.scss";

type Props = {
  text: string;
  className?: string;
};

export const CopyButton: React.FC<Props> = ({ text, className }) => {
  const [, copy] = useCopyToClipboard();

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    copy(text);
  };

  return (
    <div className={cn("ui-copy", className)}>
      {text}
      <div className="ui-copy-btn" onClick={handleClick}>
        <Copy />
      </div>
    </div>
  );
};
