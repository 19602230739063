import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
  from,
  Observable,
  split,
} from "@apollo/client";
import { DefaultOptions } from "@apollo/client";
import axios from "axios";
import { RetryLink } from "@apollo/client/link/retry";
import toaster from "components/UI/Notifications/Notification";
import { STATUS_CODES } from "../common/const/responseErrors";
import { onError } from "@apollo/client/link/error";
import {
  getMainDefinition,
  ObservableSubscription,
} from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { getCookie, removeCookie, setCookie } from "common/utils/cookies";
import { ERROR_MESSAGE } from "common/const/messages";

const wsUrl = process.env.REACT_APP_WS_API_URL ?? "";
const URL = process.env.REACT_APP_API_URL + "/query";
const apiUrl = process.env.REACT_APP_REFRESH_TOKEN_URL ?? "";

const accessToken = getCookie("access_token");
const refreshToken = getCookie("refresh_token");

export const wsClient = new SubscriptionClient(wsUrl, {
  reconnect: true,
  connectionParams: {
    authorization: accessToken ? `Bearer ${accessToken}` : null,
  },
});

const wsLink = new WebSocketLink(wsClient);

const rootDomain = process.env.REACT_APP_ROOT_DOMAIN ?? "";
let domain = "";
if (rootDomain?.indexOf(":") >= 0) {
  [domain] = rootDomain.split(":");
} else {
  domain = rootDomain;
}

const httpLink = createHttpLink({ uri: URL });

// Using this logic, queries and mutations will use HTTP as normal, and subscriptions will use WebSocket.
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });
  return forward(operation);
});

const getNewToken = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(apiUrl, {
        refresh_token: refreshToken,
      })
      .then((res) => {
        const dateNow = new Date().getTime();
        const expiredDateAccess = new Date(
          parseInt(res.data.expires_in as string, 10) * 1000 + dateNow
        );
        const expiredDateRefresh = new Date(
          parseInt(res.data.refresh_expires_in as string, 10) * 1000 + dateNow
        );
        setCookie("access_token", res.data.access_token, {
          expires: expiredDateAccess,
          domain: domain,
        });
        setCookie("refresh_token", res.data.refresh_token, {
          expires: expiredDateRefresh,
          domain: domain,
        });
        resolve(res.data.access_token);
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  });
};

const resetCookies = () => {
  removeCookie("access_token", { domain: domain });
  removeCookie("refresh_token", { domain: domain });
  removeCookie("openedCandidatesIds");
};

const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const ErrorDetector = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    let subscription: ObservableSubscription;
    const name = operation.operationName;
    try {
      subscription = forward(operation).subscribe({
        next: (result) => {
          let data = result.data;
          if (data?.[name]?.["statusCode"]) {
            if (data[name].statusCode === parseInt(STATUS_CODES["401"])) {
              resetCookies();
              window.location.replace(window.location.origin + "/login");
            }

            observer.error(data?.[name]);
          } else {
            observer.next(result);
          }
        },
        error: (networkError) => {
          observer.error(networkError);
        },
        complete: () => {
          observer.complete.bind(observer)();
        },
      });
    } catch (e) {
      observer.error(e);
    }
    return () => {
      // @ts-ignore
      if (subscription) {
        // @ts-ignore
        subscription.unsubscribe();
      }
    };
  });
});

const RetryOnError = new RetryLink({
  delay: {
    initial: 800,
    max: Infinity,
    jitter: true,
  },
  attempts: (count, operation, error) => {
    if (
      !error.message ||
      error.message !== "incorrect or expired access token"
    ) {
      // If error is not related to connection, do not retry
      return false;
    }
    operation.setContext({ retryCount: count });
    return count <= 3;
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError || graphQLErrors?.[0]) {
    toaster.error({ title: ERROR_MESSAGE.SYSTEM });
  }
});

export const client = new ApolloClient({
  uri: URL,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: from([
    RetryOnError,
    ErrorDetector,
    errorLink,
    authMiddleware,
    splitLink,
  ]),
  defaultOptions: defaultOptions,
});
