import React from "react";
import cn from "classnames";
import "./styles.scss";

export type Props = {
  icon: React.ReactNode;
  label: React.ReactNode;
  className?: string;
};

export const IconLabel: React.FC<Props> = ({ icon, label, className }) => {
  return (
    <div className={cn("icon-label nowrap", className)}>
      <div>{icon}</div>
      <span className="icon-label-text">{label}</span>
    </div>
  );
};
